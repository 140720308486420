var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lead-view", class: { loading: _vm.loading } },
    [
      _vm.lead
        ? [
            _c(
              "div",
              { staticClass: "lead-view__wrapper" },
              [
                _c("lead-menu", {
                  attrs: { lead: _vm.lead },
                  on: {
                    updateLead: function(updatedLead) {
                      return (_vm.lead = updatedLead)
                    },
                    commenterror: _vm.onCommentError
                  }
                }),
                _vm._v(" "),
                _c("lead-status-log", {
                  ref: "statuslog",
                  attrs: { lead: _vm.lead },
                  on: {
                    input: function(updatedLead) {
                      return (_vm.lead = updatedLead)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.viewShown == "products"
                  ? _c("lead-products", { attrs: { lead: _vm.lead } })
                  : _vm._e(),
                _vm._v(" "),
                _vm.viewShown == "book-telefon-mode"
                  ? _c("lead-book-phone", {
                      attrs: { lead: _vm.lead },
                      on: {
                        input: function(updatedLead) {
                          return (_vm.lead = updatedLead)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.viewShown == "company"
                  ? _c("lead-order-data-modal", {
                      attrs: {
                        versonConfig: _vm.versionConfig,
                        lead: _vm.lead
                      },
                      on: {
                        input: function(updatedLead) {
                          return (_vm.lead = updatedLead)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.viewShown == "invoice"
                  ? _c("lead-order-invoice", { attrs: { lead: _vm.lead } })
                  : _vm._e(),
                _vm._v(" "),
                _vm.viewShown == "customer-recept"
                  ? _c("lead-order-customer-recept", {
                      attrs: { lead: _vm.lead }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.viewShown == "kundevalidering"
                  ? _c("customer-validate-modal", {
                      attrs: { lead: _vm.lead },
                      on: {
                        input: function(updatedLead) {
                          return (_vm.lead = updatedLead)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-c2ff9fda", { render: render, staticRenderFns: staticRenderFns })
  }
}
<template>
    <slide class="order-flow__wrapper--8" @next="submit()" :validated="true" :overwriteSaveBtn="true" :showNextBtn="!makeOrder">
        <template slot="content">
            <div class="over-flow__text-block">
                <template v-for="(contentItem, key) in content">
                    <h3 class="over-flow__text-block__heading" v-text="contentItem.title"></h3>
                    <p v-text="contentItem.text"></p>
                    <div class="label-checkbox" v-if="contentItem.chkLabel && contentItem.chkBoxFieldName">
                        <input type="checkbox" :id="`check-${contentItem.chkBoxFieldName}`"
                            v-model="intExtraOptions[contentItem.chkBoxFieldName]" />
                        <label :for="`check-${contentItem.chkBoxFieldName}`" class="label">
                            <span class="circle"></span>
                            <span class="check"></span>
                            <span class="box"></span> {{ contentItem.chkLabel }}
                        </label>
                    </div>
                </template>
            </div>
        </template>
        <template slot="actions">
            <button v-if="makeOrder" class="btn btn--show" :class="{ 'btn--loading': loading }" @click="submit()" :disabled="loading">
                Afslut
            </button>
            <p class="error" v-if="error">Der skete en fejl under bestillingen! Tjek at du har internet og prøv igen...
            </p>
        </template>
    </slide>
</template>

<script>
import Slide from './slide.vue';

export default {
    props: {
        loading: { type: Boolean, default: false },
        error: { type: Boolean, default: false },
        content: {
            type: Array,
            default: () => ([])
        },
        extra: { type: Object, default: () => ({}) },
        makeOrder: { type: Boolean, default: false }
    },
    data() {
        return {
            intExtraOptions: {}
        }
    },
    mounted() {
        this.intExtraOptions = Object.assign({}, this.extra);
    },
    watch: {
        extra() {
            this.intExtraOptions = Object.assign({}, this.extra);
        }
    },
    methods: {
        submit() {
            this.$emit('submit', this.intExtraOptions);
        }
    },
    components: {
        'slide': Slide
    }
}
</script>
<template>
  <div class="order-receipt">
    <div class="company-content">
      <div class="confirm-order-block" v-if="showHeader">
        <h3
          class="confirm-order-block__heading"
          v-text="productData.title"
        ></h3>
      </div>
      <div class="confirm-order-block">
        <h3 class="confirm-order-block__heading">Selskabsnavn</h3>
        <div
          class="confirm-order-block__content"
          v-if="companyData.companyName"
        >
          <p v-text="companyData.companyName[0]"></p>
          <p
            v-for="(byName, key) in companyData.companyByName"
            v-text="byName"
            :key="key"
          ></p>
        </div>
      </div>

      <div class="confirm-order-block" v-if="companyData.extra &&  companyData.extra.holdingCompanyName">
        <h3 class="confirm-order-block__heading">Holdingselsab</h3>
        <div
          class="confirm-order-block__content"
          v-if="companyData.extra.holdingCompanyName"
        >
          <p v-text="companyData.companyName[0]"></p>
          <template v-if="companyData.extra.holdingCompanyByNames">
            <p
              v-for="(byName, key) in companyData.extra.holdingCompanyByNames.split(',')"
              v-text="byName"
              :key="key"
            ></p>
          </template>

        </div>
      </div>
      <div
        class="confirm-order-block"
        v-if="companyData.extra && companyData.extra.extra"
      >
        <h3 class="confirm-order-block__heading">Bemærkning</h3>
        <div class="confirm-order-block__content">
          <p v-text="companyData.extra.extra"></p>
        </div>
      </div>
      <div class="confirm-order-block" v-if="companyData.secret_owners.length">
        <h3 class="confirm-order-block__heading">Antal ejere</h3>
        <div class="confirm-order-block__content">
          <p v-text="companyData.secret_owners.length"></p>
        </div>
      </div>
      <div
        class="confirm-order-block"
        v-for="(owner, key) in companyData.secret_owners"
        :key="key"
      >
        <h3 class="confirm-order-block__heading">
          {{ numberTxt(key + 1) }} ejer
        </h3>
        <div class="confirm-order-block__content">
          <template v-if="owner.info.company_creation">
            <p>Selskabet er under stiftelse</p>
          </template>
          <template v-else>
            <p><span>Ejertype:</span> {{ getOwnerType(owner.type) }}</p>
            <p><span>Navn:</span> {{ owner.info.name }}</p>
            <p><span>Adresse:</span> {{ owner.info.address }}</p>
            <p><span>Postnr.:</span> {{ owner.info.postal }}</p>
            <p><span>By:</span> {{ owner.info.town }}</p>
            <p><span>Land:</span> {{ owner.info.country }}</p>
            <p><span>E-mail:</span> {{ owner.info.email }}</p>
            <p><span>Tlf.:</span> {{ owner.info.phone }}</p>
          </template>
        </div>
      </div>
      <div
        class="confirm-order-block"
        v-if="companyData.secret_ceo && companyData.secret_ceo.length"
      >
        <h3 class="confirm-order-block__heading">Direktører</h3>
        <div class="confirm-order-block__content">
          <p
            v-text="ceo.name"
            v-for="(ceo, ceoKey) in companyData.secret_ceo"
            :key="ceoKey"
          ></p>
        </div>
      </div>
      <div
        class="confirm-order-block"
        v-if="companyData.address && companyData.address.address"
      >
        <h3 class="confirm-order-block__heading">Selskabets adresse</h3>
        <div class="confirm-order-block__content">
          <p v-text="companyData.address.address"></p>
          <p>
            {{ companyData.address.postal }}, {{ companyData.address.town }}
          </p>
          <p v-text="companyData.address.country"></p>
        </div>
      </div>
      <div
        class="confirm-order-block"
        v-if="companyData.goal && companyData.goal[0]"
      >
        <h3 class="confirm-order-block__heading">Selskabets formål</h3>
        <div class="confirm-order-block__content">
          <p v-text="companyData.goal[0]"></p>
        </div>
      </div>
      <div class="confirm-order-block" v-if="hasAddions">
        <h3 class="confirm-order-block__heading">Tilvalg</h3>
        <div class="confirm-order-block__content">
          <p v-if="otherStuff && otherStuff.registerTax">Momsregistrering</p>
          <p v-if="extraAdditions && extraAdditions.payrollTax">Registrering for lønsumsafgift</p>
          <p v-if="extraAdditions && extraAdditions.ceocontract">Direktørkontrakt</p>
          <p v-if="otherStuff && otherStuff.registerAsCeo">Arbejdsgiverregistrering</p>
          <p v-if="otherStuff && otherStuff.registerDrinkPassus">Passus til alkoholbevilling</p>
          <p v-if="otherStuff && otherStuff.registerImportExport">Import/eksport registrering</p>
          <p v-if="otherStuff && otherStuff.registerRealOwner">Reel ejerregistrering </p>
          <p v-if="otherStuff && otherStuff.registerFirstBuyer">Passus til forkøbsret</p>
          <p v-if="otherStuff && otherStuff.capitalclass">Kapitalklasser</p>
          <p v-if="extraAdditions && extraAdditions.ownerdeal">Ejeraftale</p>
          <p v-if="extraAdditions && extraAdditions.trademark">Varemærkeregistrering</p>
          <p v-if="extraAdditions && extraAdditions.debt">Gældsbrev på anpartshaverlån</p>
        </div>
      </div>
    </div>
    <div class="confirm-order-block">
      <h3 class="confirm-order-block__heading">Pris</h3>
      <div class="confirm-order-block__content">
        <p>Produktpris <span v-price="mainProductPrice"></span></p>
        <p>Tilvalg <span v-price="otherStuffPrice"></span></p>
        <p>Gebyrer <span v-price="totalTax"></span></p>
        <p>Samlet excl. moms: <span v-price="totalPriceExVat"></span></p>
        <p>
          <span>Samlet inkl. moms: <span v-price="totalSum"></span></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Slide from "./slide.vue";
export default {
  props: {
    lead: {
      type: Object,
      default() {
        return {};
      },
    },
    product: {
      type: Object,
      default() {
        return {};
      },
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    submit() {
      this.$emit("submit", true);
    },
    getOwnerType(ownerType) {
      switch (ownerType) {
        case "person":
          return "Person";
        case "selskab":
          return "Selskab";
        case "nyt-selskab":
          return "Nyt selskab";
      }
    },
    numberTxt(count) {
      switch (count) {
        case 1:
          return "Første";
        case 2:
          return "Anden";
        case 3:
          return "Tredje";
        case 4:
          return "Fjerde";
        case 5:
          return "Femte";
        case 6:
          return "Sjette";
        case 7:
          return "Syvende";
        case 8:
          return "Ottende";
        default:
          return count + ".";
      }
    },
  },
  computed: {
    productData() {
      return this.product.product;
    },
    companyData() {
      return this.product.lead_data;
    },
    totalSum() {
      return this.totalPrice * 1.25 + this.totalTax;
    },
    totalMoms() {
      return this.totalPrice;
    },
    totalPriceExVat() {
      return this.totalPrice + this.totalTax;
    },
    totalPrice() {
      return this.mainProductPrice + this.otherStuffPrice;
    },
    totalTax() {
      return this.mainProductTax;
    },
    mainProductPrice() {
      return this.productData
        ? parseFloat(this.productData.static_product.price_excl_vat)
        : 0;
    },
    mainProductTax() {
      return this.productData && this.productData.static_product.tax
        ? parseFloat(this.productData.static_product.tax)
        : 0;
    },
    holdingCompanies() {
      return this.products.filter((item) => item.companyProduct === "holding");
    },
    otherStuff() {
      let idList = {
        registerAsCeo: 10,
        registerTax: 3,
        registerImportExport: 11,
        registerRealOwner: 1,
        registerDrinkPassus: 5,
        registerFirstBuyer: 6,
        capitalclass: 12,
      };

      let output = {}
      let hasOutput = false;
      for(let i in idList) {
        let addon = this.product.addons.filter((addon) => addon.addon_id == idList[i]);
        output[i] = (addon.length) ? true : false;
        if(!hasOutput && addon.length) {
          hasOutput = true;
        }
      }

      return (hasOutput) ? output : null;
    },
    otherStuffPrice() {
      let addonPrice = this.product.addons
        .filter(({is_tax}) => !is_tax)
        .map(
          (addon) =>
            parseFloat(addon.price) - parseFloat(addon.price) * 0.2 || 0
        )
        .reduce((a, b) => a + b, 0);

      return parseFloat(addonPrice);
    },
    extraAdditions() {
      return (this.companyData.extra) ? this.companyData.extra : {};
    },
    hasAddions() {
      const extra = (this.extraAdditions) ? Object.assign({}, this.extraAdditions, {holdingCompanyName: null}) : {};

      return (
        (extra && Object.values(extra)
          .filter((value) => value ? true : false).length) || 
        (this.otherStuff && Object.values(this.otherStuff)
          .filter((value) => value ? true : false).length)
      ) ? true : false;
    }
  },
  components: {
    slide: Slide,
  },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.slide === "company-holding-name"
        ? _c("company-name", {
            attrs: {
              title: "Hvad skal holdingselskabet hedde",
              "company-name": _vm.companyData.extra.holdingCompanyName,
              "company-by-name": _vm.holdingByNames
            },
            on: { submit: _vm.setCompanyHoldingName }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.slide === "company-name"
        ? _c("company-name", {
            attrs: {
              "company-name": _vm.companyData.companyName,
              "company-by-name": _vm.companyData.companyByName
            },
            on: { submit: _vm.setCompanyName }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.slide === "number-of-owners"
        ? _c("number-of-owners", {
            attrs: {
              "company-name": _vm.companyData.companyName,
              "number-of-owners": _vm.companyData.owners.length
            },
            on: { submit: _vm.setNumberOfOwners }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(this.companyData.owners, function(owner, key) {
        return [
          _vm.slide === "owner-type-" + key
            ? _c("owner-type", {
                key: key,
                attrs: {
                  "multiple-owners": _vm.multipleOwners,
                  "owner-number": key + 1,
                  "owner-type": _vm.companyData.owners[key].type
                },
                on: {
                  submit: function(ownerType) {
                    return _vm.setOwnerType(ownerType, key)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.slide === "owner-info-" + key
            ? _c("owner-info", {
                key: key,
                attrs: {
                  "multiple-owners": _vm.multipleOwners,
                  owner: _vm.companyData.owners[key],
                  "owner-number": key + 1
                },
                on: {
                  submit: function(ownerInfo) {
                    return _vm.setOwnerInfo(ownerInfo, key)
                  }
                }
              })
            : _vm._e()
        ]
      }),
      _vm._v(" "),
      _vm.slide === "choose-ceo"
        ? _c("choose-ceo", {
            attrs: {
              "company-name": _vm.companyData.companyName,
              owners: _vm.companyData.owners,
              "ceo-data": _vm.companyData.ceo
            },
            on: { submit: _vm.setCeoOwner }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.slide === "company-address"
        ? _c("company-address", {
            attrs: {
              "company-name": _vm.companyData.companyName,
              owners: _vm.companyData.owners,
              "company-address": _vm.companyData.address
            },
            on: { submit: _vm.setComapnyAddress }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.slide === "company-goal"
        ? _c("company-goal", {
            attrs: {
              "company-name": _vm.companyData.companyName,
              "company-goal": _vm.companyData.goal
            },
            on: { submit: _vm.setCompanyGoal }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.slide === "financial-year"
        ? _c("financial-year", {
            attrs: {
              "company-name": _vm.companyData.companyName,
              "selected-date-range": _vm.financialdaterange,
              "financial-year": _vm.companyData.financial_year
            },
            on: { submit: _vm.setFinancialYear }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.slide === "capital"
        ? _c("capital", {
            attrs: {
              "company-name": _vm.companyData.companyName,
              capital: _vm.companyData.nemreg_pricedata.capital
            },
            on: { submit: _vm.setCapital }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.slide === "incorporation-rule"
        ? _c("incorportaion-rule", {
            attrs: {
              "company-name": _vm.companyData.companyName,
              selectedIncormationsRule: _vm.selectedIncorporationRule
            },
            on: { submit: _vm.setIncorporationRule }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.extraSlides, function(extraSlide) {
        return [
          _vm.slide === extraSlide.slug
            ? _c("extra", {
                attrs: {
                  title: extraSlide.title,
                  content: extraSlide.content,
                  extra: _vm.extraValues,
                  loading: _vm.loading,
                  error: _vm.error,
                  makeOrder: _vm.percentCompleted >= 100 ? true : false
                },
                on: { submit: _vm.setExtra }
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a7b26f0c", { render: render, staticRenderFns: staticRenderFns })
  }
}
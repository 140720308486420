var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order-flow__wrapper" }, [
    _c("div", { staticClass: "order-flow__header" }, [
      _c("h1", {
        staticClass: "order-flow__heading",
        domProps: { innerHTML: _vm._s(_vm.title) }
      }),
      _vm._v(" "),
      _vm.subTitle
        ? _c("h3", {
            staticClass: "order-flow__subheading",
            domProps: { textContent: _vm._s(_vm.subTitle) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.subSubTitle
        ? _c("h4", {
            staticClass: "order-flow__subsubheading",
            domProps: { textContent: _vm._s(_vm.subSubTitle) }
          })
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "order-flow__content" },
      [
        _vm._t("content"),
        _vm._v(" "),
        _vm.orderHelp
          ? _c(
              "div",
              { staticClass: "order-help" },
              [
                _c("h3", {
                  staticClass: "order-help__heading",
                  domProps: { textContent: _vm._s(_vm.orderHelp.heading) }
                }),
                _vm._v(" "),
                _vm.orderHelp.paragraphs
                  ? _vm._l(_vm.orderHelp.paragraphs, function(text, key) {
                      return _c("p", {
                        key: key,
                        staticClass: "order-help__text",
                        domProps: { innerHTML: _vm._s(text) }
                      })
                    })
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "order-flow__actions",
        class: { "order-flow__actions--show": _vm.validated }
      },
      [
        _vm._t("actions"),
        _vm._v(" "),
        !_vm.isNavigatedFromOrder && _vm.showNextBtn
          ? _c(
              "button",
              {
                staticClass: "btn",
                class: { "btn--show": _vm.validated },
                attrs: { disabled: !this.validated },
                on: {
                  click: function($event) {
                    _vm.validated ? _vm.$emit("next") : null
                  }
                }
              },
              [_vm._v("Videre")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isNavigatedFromOrder
          ? _c(
              "button",
              {
                staticClass: "btn",
                class: { "btn--show": _vm.validated },
                attrs: { disabled: !this.validated },
                on: {
                  click: function($event) {
                    _vm.validated ? _vm.$emit("next") : null
                  }
                }
              },
              [_vm._v("Gem")]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-60192346", { render: render, staticRenderFns: staticRenderFns })
  }
}
<template>
  <div class="lead-view" :class="{ loading: loading }">
    <template v-if="lead">
      <div class="lead-view__wrapper">
        <lead-menu 
        :lead="lead"
        @updateLead="(updatedLead) => (lead = updatedLead)"
        @commenterror="onCommentError">
      </lead-menu>
        <lead-status-log
          :lead="lead"
          @input="(updatedLead) => (lead = updatedLead)"
          ref="statuslog"
        ></lead-status-log>
        <lead-products
          v-if="viewShown == 'products'"
          :lead="lead"
        ></lead-products>
        <lead-book-phone
          v-if="viewShown == 'book-telefon-mode'"
          :lead="lead"
          @input="(updatedLead) => (lead = updatedLead)"
        ></lead-book-phone>
        <lead-order-data-modal
          v-if="viewShown == 'company'"
          :versonConfig="versionConfig"
          :lead="lead"
          @input="(updatedLead) => (lead = updatedLead)"
        ></lead-order-data-modal>
        <lead-order-invoice
          v-if="viewShown == 'invoice'"
          :lead="lead"
        ></lead-order-invoice>
        <lead-order-customer-recept
          v-if="viewShown == 'customer-recept'"
          :lead="lead"
        ></lead-order-customer-recept>
        <customer-validate-modal
          v-if="viewShown == 'kundevalidering'"
          :lead="lead"
          @input="(updatedLead) => (lead = updatedLead)"
        ></customer-validate-modal>
      </div>
    </template>
  </div>
</template>

<script>
import LeadMenu from "./lead-menu.vue";
import DataStore from "../../services/datastore";
import LeadProducts from "./lead-products.vue";
import LeadBookPhone from "./lead-book-phone.vue";
import LeadOrderDataModal from "./lead-order-data-modal.vue";
import LeadOrderInvoice from "./lead-order-invoice.vue";
import LeadOrderCustomerRecept from "./lead-order-customer-recept.vue";
import CustomerValidateModal from "./custoemr-validate-modal.vue";
import LeadStatusLog from "./lead-status-log.vue";

export default {
  data() {
    return {
      lead: null,
      loading: false,
      versionConfig: {}
    };
  },
  mounted() {
    this.loadLead();
    this.loadVersionConfig();
    document.getElementById('app').classList.add('mobile-noScroll');
  },
  beforeDestroy() {
    document.getElementById('app').classList.remove('mobile-noScroll');
  },
  watch: {
    "$route.params.hash"() {
      this.loadLead();
    },
  },
  methods: {
    loadLead() {
      if (this.loading) return;

      this.loading = true;
      let hash = this.$route.params.hash;
      DataStore.getLead(hash)
        .then((data) => {
          this.lead = data.data;
          this.loading = false;
        })
        .catch((error) => {
          window.location = "/";
        });
    },
    loadVersionConfig() {
      DataStore.getLeadVersionConfig()
        .then((data) => {
          try {
            this.versionConfig = data.data;
          } catch(e) {
            console.error(e);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onCommentError() {
      if(this.$refs.statuslog) {
        this.$refs.statuslog.addCommentError();
      }
    }
  },
  computed: {
    viewShown() {
      if (!this.lead) {
        return null;
      }

      return this.$route.params.view || "info";
    },
  },
  components: {
    LeadMenu,
    LeadProducts,
    LeadBookPhone,
    LeadOrderDataModal,
    CustomerValidateModal,
    LeadOrderInvoice,
    LeadOrderCustomerRecept,
    LeadStatusLog
  },
};
</script>
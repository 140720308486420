var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order-receipt" }, [
    _c(
      "div",
      { staticClass: "company-content" },
      [
        _vm.showHeader
          ? _c("div", { staticClass: "confirm-order-block" }, [
              _c("h3", {
                staticClass: "confirm-order-block__heading",
                domProps: { textContent: _vm._s(_vm.productData.title) }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "confirm-order-block" }, [
          _c("h3", { staticClass: "confirm-order-block__heading" }, [
            _vm._v("Selskabsnavn")
          ]),
          _vm._v(" "),
          _vm.companyData.companyName
            ? _c(
                "div",
                { staticClass: "confirm-order-block__content" },
                [
                  _c("p", {
                    domProps: {
                      textContent: _vm._s(_vm.companyData.companyName[0])
                    }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.companyData.companyByName, function(byName, key) {
                    return _c("p", {
                      key: key,
                      domProps: { textContent: _vm._s(byName) }
                    })
                  })
                ],
                2
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.companyData.extra && _vm.companyData.extra.holdingCompanyName
          ? _c("div", { staticClass: "confirm-order-block" }, [
              _c("h3", { staticClass: "confirm-order-block__heading" }, [
                _vm._v("Holdingselsab")
              ]),
              _vm._v(" "),
              _vm.companyData.extra.holdingCompanyName
                ? _c(
                    "div",
                    { staticClass: "confirm-order-block__content" },
                    [
                      _c("p", {
                        domProps: {
                          textContent: _vm._s(_vm.companyData.companyName[0])
                        }
                      }),
                      _vm._v(" "),
                      _vm.companyData.extra.holdingCompanyByNames
                        ? _vm._l(
                            _vm.companyData.extra.holdingCompanyByNames.split(
                              ","
                            ),
                            function(byName, key) {
                              return _c("p", {
                                key: key,
                                domProps: { textContent: _vm._s(byName) }
                              })
                            }
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.companyData.extra && _vm.companyData.extra.extra
          ? _c("div", { staticClass: "confirm-order-block" }, [
              _c("h3", { staticClass: "confirm-order-block__heading" }, [
                _vm._v("Bemærkning")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "confirm-order-block__content" }, [
                _c("p", {
                  domProps: { textContent: _vm._s(_vm.companyData.extra.extra) }
                })
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.companyData.secret_owners.length
          ? _c("div", { staticClass: "confirm-order-block" }, [
              _c("h3", { staticClass: "confirm-order-block__heading" }, [
                _vm._v("Antal ejere")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "confirm-order-block__content" }, [
                _c("p", {
                  domProps: {
                    textContent: _vm._s(_vm.companyData.secret_owners.length)
                  }
                })
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.companyData.secret_owners, function(owner, key) {
          return _c("div", { key: key, staticClass: "confirm-order-block" }, [
            _c("h3", { staticClass: "confirm-order-block__heading" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.numberTxt(key + 1)) + " ejer\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "confirm-order-block__content" },
              [
                owner.info.company_creation
                  ? [_c("p", [_vm._v("Selskabet er under stiftelse")])]
                  : [
                      _c("p", [
                        _c("span", [_vm._v("Ejertype:")]),
                        _vm._v(" " + _vm._s(_vm.getOwnerType(owner.type)))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", [_vm._v("Navn:")]),
                        _vm._v(" " + _vm._s(owner.info.name))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", [_vm._v("Adresse:")]),
                        _vm._v(" " + _vm._s(owner.info.address))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", [_vm._v("Postnr.:")]),
                        _vm._v(" " + _vm._s(owner.info.postal))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", [_vm._v("By:")]),
                        _vm._v(" " + _vm._s(owner.info.town))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", [_vm._v("Land:")]),
                        _vm._v(" " + _vm._s(owner.info.country))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", [_vm._v("E-mail:")]),
                        _vm._v(" " + _vm._s(owner.info.email))
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("span", [_vm._v("Tlf.:")]),
                        _vm._v(" " + _vm._s(owner.info.phone))
                      ])
                    ]
              ],
              2
            )
          ])
        }),
        _vm._v(" "),
        _vm.companyData.secret_ceo && _vm.companyData.secret_ceo.length
          ? _c("div", { staticClass: "confirm-order-block" }, [
              _c("h3", { staticClass: "confirm-order-block__heading" }, [
                _vm._v("Direktører")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "confirm-order-block__content" },
                _vm._l(_vm.companyData.secret_ceo, function(ceo, ceoKey) {
                  return _c("p", {
                    key: ceoKey,
                    domProps: { textContent: _vm._s(ceo.name) }
                  })
                }),
                0
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.companyData.address && _vm.companyData.address.address
          ? _c("div", { staticClass: "confirm-order-block" }, [
              _c("h3", { staticClass: "confirm-order-block__heading" }, [
                _vm._v("Selskabets adresse")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "confirm-order-block__content" }, [
                _c("p", {
                  domProps: {
                    textContent: _vm._s(_vm.companyData.address.address)
                  }
                }),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.companyData.address.postal) +
                      ", " +
                      _vm._s(_vm.companyData.address.town) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    textContent: _vm._s(_vm.companyData.address.country)
                  }
                })
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.companyData.goal && _vm.companyData.goal[0]
          ? _c("div", { staticClass: "confirm-order-block" }, [
              _c("h3", { staticClass: "confirm-order-block__heading" }, [
                _vm._v("Selskabets formål")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "confirm-order-block__content" }, [
                _c("p", {
                  domProps: { textContent: _vm._s(_vm.companyData.goal[0]) }
                })
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.hasAddions
          ? _c("div", { staticClass: "confirm-order-block" }, [
              _c("h3", { staticClass: "confirm-order-block__heading" }, [
                _vm._v("Tilvalg")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "confirm-order-block__content" }, [
                _vm.otherStuff && _vm.otherStuff.registerTax
                  ? _c("p", [_vm._v("Momsregistrering")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.extraAdditions && _vm.extraAdditions.payrollTax
                  ? _c("p", [_vm._v("Registrering for lønsumsafgift")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.extraAdditions && _vm.extraAdditions.ceocontract
                  ? _c("p", [_vm._v("Direktørkontrakt")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.otherStuff && _vm.otherStuff.registerAsCeo
                  ? _c("p", [_vm._v("Arbejdsgiverregistrering")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.otherStuff && _vm.otherStuff.registerDrinkPassus
                  ? _c("p", [_vm._v("Passus til alkoholbevilling")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.otherStuff && _vm.otherStuff.registerImportExport
                  ? _c("p", [_vm._v("Import/eksport registrering")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.otherStuff && _vm.otherStuff.registerRealOwner
                  ? _c("p", [_vm._v("Reel ejerregistrering ")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.otherStuff && _vm.otherStuff.registerFirstBuyer
                  ? _c("p", [_vm._v("Passus til forkøbsret")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.otherStuff && _vm.otherStuff.capitalclass
                  ? _c("p", [_vm._v("Kapitalklasser")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.extraAdditions && _vm.extraAdditions.ownerdeal
                  ? _c("p", [_vm._v("Ejeraftale")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.extraAdditions && _vm.extraAdditions.trademark
                  ? _c("p", [_vm._v("Varemærkeregistrering")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.extraAdditions && _vm.extraAdditions.debt
                  ? _c("p", [_vm._v("Gældsbrev på anpartshaverlån")])
                  : _vm._e()
              ])
            ])
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "confirm-order-block" }, [
      _c("h3", { staticClass: "confirm-order-block__heading" }, [
        _vm._v("Pris")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "confirm-order-block__content" }, [
        _c("p", [
          _vm._v("Produktpris "),
          _c("span", {
            directives: [
              {
                name: "price",
                rawName: "v-price",
                value: _vm.mainProductPrice,
                expression: "mainProductPrice"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Tilvalg "),
          _c("span", {
            directives: [
              {
                name: "price",
                rawName: "v-price",
                value: _vm.otherStuffPrice,
                expression: "otherStuffPrice"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Gebyrer "),
          _c("span", {
            directives: [
              {
                name: "price",
                rawName: "v-price",
                value: _vm.totalTax,
                expression: "totalTax"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Samlet excl. moms: "),
          _c("span", {
            directives: [
              {
                name: "price",
                rawName: "v-price",
                value: _vm.totalPriceExVat,
                expression: "totalPriceExVat"
              }
            ]
          })
        ]),
        _vm._v(" "),
        _c("p", [
          _c("span", [
            _vm._v("Samlet inkl. moms: "),
            _c("span", {
              directives: [
                {
                  name: "price",
                  rawName: "v-price",
                  value: _vm.totalSum,
                  expression: "totalSum"
                }
              ]
            })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-672e65e8", { render: render, staticRenderFns: staticRenderFns })
  }
}
<template>
  <div>
    <company-name
      v-if="slide === 'company-holding-name'"
      title="Hvad skal holdingselskabet hedde"
      @submit="setCompanyHoldingName"
      :company-name="companyData.extra.holdingCompanyName"
      :company-by-name="holdingByNames"
    ></company-name>

    <company-name
      v-if="slide === 'company-name'"
      @submit="setCompanyName"
      :company-name="companyData.companyName"
      :company-by-name="companyData.companyByName"
    ></company-name>

    <number-of-owners
      v-if="slide === 'number-of-owners'"
      @submit="setNumberOfOwners"
      :company-name="companyData.companyName"
      :number-of-owners="companyData.owners.length"
    ></number-of-owners>
    <template v-for="(owner, key) in this.companyData.owners">
      <owner-type
        v-if="slide === 'owner-type-' + key"
        :multiple-owners="multipleOwners"
        :key="key"
        :owner-number="key + 1"
        :owner-type="companyData.owners[key].type"
        @submit="(ownerType) => setOwnerType(ownerType, key)"
      ></owner-type>
      <owner-info
        v-if="slide === 'owner-info-' + key"
        :multiple-owners="multipleOwners"
        :key="key"
        :owner="companyData.owners[key]"
        :owner-number="key + 1"
        @submit="(ownerInfo) => setOwnerInfo(ownerInfo, key)"
      ></owner-info>
    </template>

    <choose-ceo
      v-if="slide === 'choose-ceo'"
      :company-name="companyData.companyName"
      :owners="companyData.owners"
      :ceo-data="companyData.ceo"
      @submit="setCeoOwner"
    ></choose-ceo>
    <company-address
      v-if="slide === 'company-address'"
      :company-name="companyData.companyName"
      :owners="companyData.owners"
      :company-address="companyData.address"
      @submit="setComapnyAddress"
    ></company-address>
    <company-goal
      v-if="slide === 'company-goal'"
      :company-name="companyData.companyName"
      :company-goal="companyData.goal"
      @submit="setCompanyGoal"
    ></company-goal>
    <financial-year
      v-if="slide === 'financial-year'"
      :company-name="companyData.companyName"
      :selected-date-range="financialdaterange"
      :financial-year="companyData.financial_year"
      @submit="setFinancialYear"
    ></financial-year>
    <capital
      v-if="slide === 'capital'"
      :company-name="companyData.companyName"
      :capital="companyData.nemreg_pricedata.capital"
      @submit="setCapital"
    ></capital>
    <incorportaion-rule
      v-if="slide === 'incorporation-rule'"
      :company-name="companyData.companyName"
      :selectedIncormationsRule="selectedIncorporationRule"
      @submit="setIncorporationRule"
    ></incorportaion-rule>
    <template v-for="extraSlide in extraSlides">
      <extra  
        v-if="slide === extraSlide.slug"
        :title="extraSlide.title"
        :content="extraSlide.content"
        :extra="extraValues"
        @submit="setExtra"
        :loading="loading"
        :error="error"
        :makeOrder="percentCompleted >= 100 ? true : false"
      ></extra>
    </template>
  </div>
</template>

<script>
import CompanyName from "./slides/company-name.vue";
import NumberOfOwners from "./slides/number-of-owners.vue";
import OwnerType from "./slides/owner-type.vue";
import OwnerInfo from "./slides/owner-info.vue";
import ChooseCeo from "./slides/choose-ceo.vue";
import CompanyAddress from "./slides/company-address.vue";
import CompanyGoal from "./slides/company-goal.vue";
import CompanyType from "./slides/company-type.vue";
import FinancialYear from "./slides/company-financial-year.vue";
import Capital from "./slides/capital.vue";
import IncorportaionRule from "./slides/incorportaion-rule.vue";
import Extra from "./slides/extra.vue";
import Moment from "moment";

const VERSIONS = {
  1: {
    screenCount: 3,
    defaultOwnerCount: 1,
    ownersAsCeo: true,
    defaultFinancialYear: true,
    defaultCapital: 40000
  },
  2: {
    screenCount: 3,
    defaultOwnerCount: 2,
    noOwners: true,
    defualtIncorporationRule: true,
    defaultFinancialYear: true,
    defaultCapital: 40000
  },
  3: {
    screenCount: 4,
    setHoldingName: true,
    defaultOwnerCount: 1,
    ownersAsCeo: true,
    defaultFinancialYear: true,
    defaultCapital: 40000
  }
}

export default {
  props: {
    slide: { type: String, default: "company-name" },
    company: { type: Object, default: null },
    slideNumber: { tyoe: Number, default: 0 },
    error: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    version: { type: Number, default: null }
  },
  data() {
    return {
      intSlideNumber: 1,
      selectedIncorporationRule: -1,
      financialdaterange: "",
      companyData: {
        companyType: null,
        companyName: "",
        companyByName: [],
        owners: [],
        ceo: null,
        address: null,
        goal: "",
        financial_year: {
          start: "",
          end: "",
        },
        nemreg_pricedata: {
          type: "deponering",
          exchange_rate: 0,
          capital: 0,
          deposit: 0,
        },
        incorporation_rule: "",
        extra: {},
      },
    };
  },
  mounted() {
    this.updateCompanyData();
    this.$emit("percent", this.percentCompleted);
  },
  watch: {
    slideNumber(nextSlide, prevSlide) {
      if (nextSlide > prevSlide) {
        this.intSlideNumber += 1;
      } else {
        this.intSlideNumber -= 1;
      }
    },
    percentCompleted() {
      this.$emit("percent", this.percentCompleted);
    },
  },
  computed: {
    multipleOwners() {
      return this.companyData.owners.length > 1 ? true : false;
    },
    multipleCeo() {
      return this.companyData.ceo && this.companyData.ceo.filter((ceo) => ceo.selected).length > 1 ? true : false;
    },
    numberOfScreens() {
      let numberOfOwners = 2 * 2;
      if (this.versionData.defaultOwnerCount) {
        numberOfOwners = this.versionData.defaultOwnerCount * 2;
      } else if (this.companyData.owners.length > 0) {
        numberOfOwners = this.companyData.owners.length * 2;
      }

      const screenCount = (this.versionData.screenCount) ? this.versionData.screenCount : 7;

      let screenNumber = screenCount + this.extraSlides.length;
      if (this.multipleCeo && !this.versionData.defualtIncorporationRule) {
        screenNumber += 1;
      }

      return screenNumber + numberOfOwners;
    },
    percentCompleted() {
      let p = ((this.intSlideNumber / this.numberOfScreens) * 100).toFixed(0);
      return p < 100 ? parseInt(p) : 100;
    },
    extraSlides() {
      const slides = [];

      //Momsregistrering og lønsumsafgift
      slides.push({
        title: 'Momsregistering & Lønsumsafgift',
        slug: 'momsregistreing',
        content: [
          {
            title: 'Momsregistrering',
            text: 'Skal selskabet have aktiv drift og sælge momspligtige varer og ydelser for mere end 50.000,00 årligt skal selskabet have gennemført en momsregistrering.',
            chkLabel: 'Ja tak, momsregistrer selskabet (+495)',
            chkBoxFieldName: 'registerTax'
          },
          {
            title: 'Registrering for lønsumsafgift',
            text: 'Skal selskabet have aktiv drift og sælge momsfrie ydelser der er omfattet af lønsumsafgift for mere end 80.000 årligt, skal selskabet have en lønsumsregistrering.',
            chkLabel: 'Ja tak, registrer selskabet for lønsumsafgift (+495)',
            chkBoxFieldName: 'payrollTax'
          }
        ]
      });

      //Direktørkontrakt
      slides.push({
        title: 'Direktørkontrakt',
        slug: 'direktorkontrakt',
        content: [
          {
            title: 'Lovpligtig direktørkontrakt',
            text: 'Direktørkontrakten er lovpligtig, hvis du indgår aftale mellem dig selv og selskabet, eksempelvis om udbetaling af løn. Du kan også blive bedt om at fremvise kontrakten overfor Erhvervsstyrelsen, Skattestyrelsen eller banken og direktørkontrakten er derfor i alle tilfælde en kraftig anbefaling.',
            chkLabel: 'Ja tak, opret en direktørkontrakt (kr. 995,-)',
            chkBoxFieldName: 'ceocontract'
          }
        ]
      });

      //A-Skat
      slides.push({
        title: 'A-skat',
        slug: 'a-skat',
        content: [
          {
            title: 'A-skat',
            text: 'Skal selskabet udbetale løn til direktør eller andre ansatte skal der foretages arbejdsgiverregistrering, så du kan indberette A-skat.',
            chkLabel: 'Ja tak, registrer selskabet for A-skat (kr. 495,-)',
            chkBoxFieldName: 'registerAsCeo'
          }
        ]
      });

      //Alkoholdbevilling
      slides.push({
        title: 'Alkoholdbevilling & Registrering af import/eksport',
        slug: 'alkoholbevilling',
        content: [
          {
            title: 'Passus til alkoholbevilling',
            text: 'Skal selskabet søge om alkoholbevilling for udskænkning af alkohol, kræves det at vedtægterne opfylder resturationslovens begrænsninger. Ansøgningen påhviler selskabet efter stiftelsen.',
            chkLabel: 'Ja tak, indsæt passus til alkoholbevilling (kr. 495,-)',
            chkBoxFieldName: 'registerDrinkPassus'
          },
          {
            title: 'Registrering for import og eksport',
            text: 'Skal selskabet importere eller eksportere fysiske varer uden for EU kræver det en registrering for import/eksport.',
            chkLabel: 'Ja tak, registrer selskaber for import og eksport (kr. 495,-)',
            chkBoxFieldName: 'registerImportExport'
          }
        ]
      });

      return slides;
    },
    extraValues() {
      return Object.assign({}, this.companyData.otherStuff, this.companyData.extra);
    },
    versionData() {
      return (this.version && VERSIONS[this.version]) ? VERSIONS[this.version] : {};
    },
    holdingByNames() {
      return (this.companyData.extra && this.companyData.extra.holdingByNames) ? this.companyData.extra.holdingByNames.split(',') : [ ];
    }
  },
  methods: {
    updateCompanyData() {
      if (this.company) {
        this.companyData = Object.assign({}, this.companyData, this.company);
        if (this.companyData.completed) {
          this.intSlideNumber = this.numberOfScreens;
        }
      }
    },
    navigateToSlide(slide, meta) {
      this.$emit("slide", slide);
    },
    goToSlide(slide) {
      this.navigateToSlide(slide);
    },
    setCompanyHoldingName({companyName, companyByName}) {
      this.companyData.extra.holdingCompanyName = companyName;
      if(companyByName.length) {
        this.companyData.extra.holdingCompanyByNames = companyByName.join(',');
      }
      this.goToSlide("company-name");
    },
    setCompanyName(companyNameObj) {
      this.companyData.companyName = companyNameObj.companyName;
      this.companyData.companyByName = companyNameObj.companyByName;

      if (this.versionData.defaultOwnerCount) {
        this.setNumberOfOwners(this.versionData.defaultOwnerCount);
      } else {
        this.goToSlide("number-of-owners");
      }
    },
    setNumberOfOwners(ownerNumber) {
      let owners = [];
      for (let i = 0; i < ownerNumber; i++) {
        owners.push({
          type: "",
          info: {},
        });
      }

      this.$set(this.companyData, "owners", owners);
      this.goToSlide("owner-type-0");
    },
    setOwnerType(ownerType, key) {
      let ownerData = this.companyData.owners[key];
      ownerData.type = ownerType;
      this.$set(this.companyData.owners, key, ownerData);
      this.goToSlide("owner-info-" + key);
    },
    setOwnerInfo(ownerInfo, key) {
      let ownerData = this.companyData.owners[key];
      ownerData.info = ownerInfo;
      this.$set(this.companyData.owners, key, ownerData);

      if (this.companyData.owners.length === key + 1) {
        
        if (this.versionData.noOwners) {
          this.setCeoOwner([]);
          return;
        }

        if (this.versionData.ownersAsCeo) {
          this.setOwnersAsCeo();
          return;
        }

        this.goToSlide("choose-ceo");
      } else {
        this.goToSlide("owner-type-" + (key + 1));
      }
    },
    setOwnersAsCeo() {
      const owners = this.companyData.owners.filter((owner) => (owner.type === 'person'));
      if (owners.length) {
        this.setCeoOwner(owners.map((owner, id) => ({
          id,
          name: owner.info.name,
          email: owner.info.email,
          phone: owner.info.phone,
          secret_identifier: owner.info.secret_identifier,
          address: owner.info.address,
          postal: owner.info.postal,
          country: owner.info.country,
          town: owner.info.town,
          selected: true
        })))
      } else {
        this.setCeoOwner([]);
      }
    },
    setCeoOwner(ceoData) {
      this.$set(this.companyData, "ceo", ceoData);
      this.goToSlide("company-address");
    },
    setComapnyAddress(addressData) {
      this.$set(this.companyData, "address", addressData);
      this.goToSlide("company-goal");
    },
    setCompanyGoal(goalTxt) {
      this.$set(this.companyData, "goal", goalTxt);
      if (this.versionData.defaultFinancialYear) {
        this.setYearAsFinancialYeear();
      } else {
        this.goToSlide("financial-year");
      }
    },
    setYearAsFinancialYeear() {
      this.setFinancialYear({
        financialYear: {
          start: Moment('1/1', 'DD/MM').format("YYYY-MM-DD"),
          end: Moment('31/12', 'DD/MM').format("YYYY-MM-DD")
        },
        selectedDateRange: 'year'
      });
    },
    setFinancialYear({ financialYear, selectedDateRange }) {
      this.financialdaterange = selectedDateRange;
      this.$set(
        this.companyData,
        "financial_year",
        Object.assign({}, financialYear)
      );

      if (this.versionData.defaultCapital) {
        this.setCapital({ capital: this.versionData.defaultCapital });
      } else {
        this.goToSlide("capital");
      }
    },
    setCapital({ capital }) {
      this.$set(
        this.companyData,
        "nemreg_pricedata",
        Object.assign({}, this.companyData.nemreg_pricedata, { capital })
      );

      if (this.multipleCeo) {
        if(this.versionData.defualtIncorporationRule) {
          this.setIncorporationRule({
            incorporationRule: 'Selskabet tegnes af en direktør',
            selectedRule: 1
          });
          return;
        }

        this.goToSlide('incorporation-rule');
      } else {
        this.$set(this.companyData, "incorporation_rule", "Selskabet tegnes af en direktør");
        this.showExtraOrCreateCompany();
      }
    },
    setIncorporationRule({ incorporationRule, selectedRule }) {
      this.selectedIncorporationRule = selectedRule;
      this.$set(this.companyData, "incorporation_rule", incorporationRule);
      this.showExtraOrCreateCompany();
    },
    setExtra(data) {

      //Set data accoding to fields
      Object.keys(data).map((key) => {
        switch (key) {
          case 'registerTax':
          case 'registerAsCeo':
          case 'registerImportExport':
          case 'registerDrinkPassus':
            const otherStuff = {};
            otherStuff[key] = data[key];
            this.$set(this.companyData, "otherStuff", Object.assign({}, this.companyData.otherStuff, otherStuff));
            break;
          default:
            const extra = {};
            extra[key] = data[key];
            this.$set(this.companyData, "extra", Object.assign({}, this.companyData.extra, extra));
            break;
        }
      });

      const currentSlide = this.extraSlides.find(({ slug }) => slug == this.slide);
      if (currentSlide) {
        const idx = this.extraSlides.indexOf(currentSlide);
        const next = idx + 1;
        if (this.extraSlides.length - 1 >= next) {
          this.goToSlide(this.extraSlides[next].slug);
        } else {
          this.setCompany();
        }
      }
    },
    showExtraOrCreateCompany() {
      if (this.extraSlides.length) {
        this.goToSlide(this.extraSlides[0].slug);
      } else {
        this.setCompany();
      }
    },
    setCompany() {
      this.$emit(
        "submit",
        Object.assign({}, this.companyData, { completed: true })
      );
    },
  },
  components: {
    "company-name": CompanyName,
    "number-of-owners": NumberOfOwners,
    "owner-type": OwnerType,
    "owner-info": OwnerInfo,
    "choose-ceo": ChooseCeo,
    "company-address": CompanyAddress,
    "company-goal": CompanyGoal,
    "company-type": CompanyType,
    FinancialYear,
    Capital,
    IncorportaionRule,
    Extra
  },
};
</script>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slide",
    {
      staticClass: "order-flow__wrapper--8",
      attrs: {
        validated: true,
        overwriteSaveBtn: true,
        showNextBtn: !_vm.makeOrder
      },
      on: {
        next: function($event) {
          return _vm.submit()
        }
      }
    },
    [
      _c("template", { slot: "content" }, [
        _c(
          "div",
          { staticClass: "over-flow__text-block" },
          [
            _vm._l(_vm.content, function(contentItem, key) {
              return [
                _c("h3", {
                  staticClass: "over-flow__text-block__heading",
                  domProps: { textContent: _vm._s(contentItem.title) }
                }),
                _vm._v(" "),
                _c("p", {
                  domProps: { textContent: _vm._s(contentItem.text) }
                }),
                _vm._v(" "),
                contentItem.chkLabel && contentItem.chkBoxFieldName
                  ? _c("div", { staticClass: "label-checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.intExtraOptions[contentItem.chkBoxFieldName],
                            expression:
                              "intExtraOptions[contentItem.chkBoxFieldName]"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "check-" + contentItem.chkBoxFieldName
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.intExtraOptions[contentItem.chkBoxFieldName]
                          )
                            ? _vm._i(
                                _vm.intExtraOptions[
                                  contentItem.chkBoxFieldName
                                ],
                                null
                              ) > -1
                            : _vm.intExtraOptions[contentItem.chkBoxFieldName]
                        },
                        on: {
                          change: function($event) {
                            var $$a =
                                _vm.intExtraOptions[
                                  contentItem.chkBoxFieldName
                                ],
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.intExtraOptions,
                                    contentItem.chkBoxFieldName,
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.intExtraOptions,
                                    contentItem.chkBoxFieldName,
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.intExtraOptions,
                                contentItem.chkBoxFieldName,
                                $$c
                              )
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "label",
                          attrs: { for: "check-" + contentItem.chkBoxFieldName }
                        },
                        [
                          _c("span", { staticClass: "circle" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "check" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "box" }),
                          _vm._v(
                            " " +
                              _vm._s(contentItem.chkLabel) +
                              "\n                    "
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("template", { slot: "actions" }, [
        _vm.makeOrder
          ? _c(
              "button",
              {
                staticClass: "btn btn--show",
                class: { "btn--loading": _vm.loading },
                attrs: { disabled: _vm.loading },
                on: {
                  click: function($event) {
                    return _vm.submit()
                  }
                }
              },
              [_vm._v("\n            Afslut\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.error
          ? _c("p", { staticClass: "error" }, [
              _vm._v(
                "Der skete en fejl under bestillingen! Tjek at du har internet og prøv igen...\n        "
              )
            ])
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ff0d9bb6", { render: render, staticRenderFns: staticRenderFns })
  }
}